
import React from 'react'
import { Container } from 'react-bootstrap'
import { motion } from "framer-motion";

const PrivacyPolitics = () => {

    return (
        <motion.div className="about" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="App">
                <Container fluid className="position-relative overflow-hidden">
                    <div className="divider gradient mt-5"></div>
                    <Container fluid="sm" className="mb-4">
                        <h1 className="text-main-color text-center mt-4 text-uppercase">
                            TERMOS DE USO E POLÍTICA DE PRIVACIDADE
                        </h1>
                        <h6 class='mb-5 text-center'>(Última atualização em 22 de junho de 2022)</h6>
                        <textarea class='form-control' style={{ height: '500px' }}>
                            Olá, seja bem-vindo à FLEX!

                            Estes Termos de Uso (“Termos”) regem o relacionamento entre você (também nos referimos a você como “USUÁRIO”) e a FLEX INTERATIVA S.A. (“FLEX” ou “Nós”), pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o nº 17.298.148/0001-57 e domiciliada em São Paulo, Estado de São Paulo, Rua Ribeiro do Vale, nº 152, conjunto 174, com entrada pela Rua Pitu, nº 72, Brooklin Paulista, São Paulo, SP, CEP 04568-000, ou qualquer de suas afiliadas (“FLEX”).

                            O objetivo destes Termos e explicar como você, USUÁRIO, poderá fazer o uso dos jogos, sites, aplicativos e serviços da FLEX (“Plataforma”). O uso do aplicativo e da Plataforma, é também regulado pela Política de Privacidade da Flex, que você poderá consultar no endereço [inserir link] e outras políticas relevantes, as quais são parte integrante desses Termos e, por tal motivo, deverão ser interpretadas conjuntamente.

                            A FLEX proporciona a Plataforma para você com a condição da aceitação de todos os Termos, condições políticas e avisos aqui declarados.

                            Qualquer USUÁRIO que atenda aos requisitos destes Termos, que pretenda utilizar a Plataforma, deverá ler e manifestar aceitação destes Termos e da Política de Privacidade, que são submetidos à apreciação de todo novo USUÁRIO no momento de seu cadastro. Ao clicar em “de acordo”, você manifesta haver lido, compreendido e aceitado sem ressalvas à todas as cláusulas, termos e condições destes Termos. Ao se cadastrar, acessar e usufruir dos serviços, você estará declarando expressamente que leu, compreendeu e concordou com estes Termos.

                            Antes de acessar ou utilizar a Plataforma, o que inclui navegar no aplicativo da FLEX (“APP”), bem como acessar seus jogos e serviços, você deve concordar com os Termos e a Política de Privacidade. É necessário também que você registre uma conta na Plataforma (“Conta”). Ao registrar uma conta ou usar a Plataforma de outro modo, você declara ter 18 (dezoito) anos de idade ou ser maior de idade em seu estado, território ou país. Menores de 18 (dezoito) anos, ou menores de idade em seu estado, território ou país, poderão utilizar a Plataforma somente com o envolvimento de pai ou responsável, mediante uso da conta de pais ou responsáveis e segundo os presentes Termos.

                            Caso você acesse o Plataforma a partir de um Site de Rede Social (“SRS”), como o Facebook ou o Google+, você deve obedecer aos termos de serviço/uso desses sites, bem como a estes Termos de Uso. Caso você adquira o APP por meio da Apple Store ou da Google Play, você também deverá obedecer aos termos de uso dessas plataformas.

                            Caso você, USUÁRIO, não esteja de acordo com estes Termos e/ou com a Política de Privacidade ou quaisquer outros documentos pertinentes, solicitamos que o USUÁRIO não instale, use, acesse ou faça o cadastro na Plataforma.

                            A FLEX se reserva o direito de, a seu exclusivo critério, alterar, modificar, adicionar ou remover partes destes Termos, de sua Política de Privacidade, bem como de quaisquer outros documentos públicos da FLEX. Qualquer alteração, modificação e/ou atualização de tais documentos passará a vigorar a partir da data de sua publicação na Plataforma e deverá ser integralmente observada pelo USUÁRIO. A continuação do uso da Plataforma implicará na aceitação de tais alterações.

                            Todos os serviços prestados a você pela FLEX serão adiante denominados “Serviços”.


                            INFORMAÇÕES IMPORTANTES
                            A PLATAFORMA E TODAS AS PÁGINAS, MÓDULOS E FUNCIONALIDADES A ELA RELACIONADOS PERTENCEM EXCLUSIVAMENTE À FLEX.

                            POR FAVOR, LEIA ATENTAMENTE ESTES TERMOS ANTES DE SE CADASTRAR, ACESSAR OU USUFRUIR DAS PLATAFORMAS E DOS SERVIÇOS. AO SE CADASTRAR E UTILIZAR AS PLATAFORMAS E OS SERVIÇOS, VOCÊ ESTÁ DECLARANDO EXPRESSAMENTE QUE LEU, COMPREENDEU E CONCORDOU COM ESTES TERMOS.

                            A QUALQUER TEMPO OS TERMOS PODEM SOFRER ALTERAÇÕES, QUE VIGORARÃO IMEDIATAMENTE A PARTIR DO MOMENTO EM QUE FOREM ADICIONADAS AO SITE https://painel.flexuniverse.com.br.

                            HAVENDO SUSPEITA DE RISCO OU FRAUDE DETECTADA PELA FLEX, COMO MEDIDA DE SEGURANÇA CIBERNÉTICA, A FLEX SE RESERVA O DIREITO DE CANCELAR OU SUSPENDER O SEU CADASTRO OU ACESSO A QUALQUER TEMPO, SEM A NECESSIDADE DE AVISO PRÉVIO.

                            A COMUNICAÇÃO ENTRE USUÁRIO E A FLEX, INCLUINDO AS INFORMAÇÕES, ORIENTAÇÕES E ESPECIFICAÇÕES DO SUPORTE TÉCNICO E SERVIÇOS, SE DARÁ POR MEIO DE E-MAIL, APP, PLATAFORMA, WHATSAPP® OU POR QUALQUER OUTRO MEIO DE COMUICAÇÃO DISPONIBILIZADO À FLEX PARA VOCÊ.

                            EM CASO DE DISCORDÂNCIA COM ALGUMA OU COM TODAS AS DISPOSIÇÕES AQUI CONTIDAS, CESSE IMEDIATAMENTE O USO DE NOSSAS PLATAFORMAS E SERVIÇOS.


                            DEFINIÇÕES

                            As seguintes definições serão aplicadas nestes Termos e, qualquer referência ao singular inclui o plural e vice-versa.

                            AFILIADA: significa toda e qualquer a sociedade controlada, diretamente ou indiretamente, pela FLEX.

                            USUÁRIO: significa uma pessoa, seja ela física ou jurídica, que possua cadastro perante a Plataforma e/ou Site da FLEX, que está autorizada a utilizar a Plataforma FLEX. O USUÁRIO poderá utilizar a Plataforma como Anfitrião ou Participante, independentemente de o acesso ser feito por dispositivos móveis ou pelo aplicativo desktop.

                            ANFITRIÃO: significa o próprio USUÁRIO ou pessoa por ele autorizado que possui o direito de sediar reuniões, eventos, atividades, jogos etc. O Anfitrião poderá fazer comunicação por áudio com todos os participantes; silenciar participantes individual ou coletivamente; exibir webcam; escolher apresentação ou atividades no painel de controle; iniciar e pausar atividades; visualizar a tela dos participantes; visualizar câmeras.

                            PARTICIPANTE: significa o próprio USUÁRIO e/ou quaisquer pessoas que utilizem a Plataforma para acompanhar uma reunião, evento, atividade, jogo etc. O Participante não possui nenhuma função de controle da sala. O Participante possui controle apenas sobre o seu áudio e webcam, caso o Anfitrião tenha concedido permissão aos Participantes de usarem o seu microfone e webcam.

                            CADASTRO: são todas as informações, documentos, referentes a pessoa natural ou jurídica, especialmente no que tange os números de CPF ou CNPJ e endereço residencial ou comercial.

                            DADOS DE ACESSO: são os dados que permitem que o USUÁRIO acesse a Plataforma, com as credenciais e senha.

                            LICENÇA: é a licença de uso da Plataforma FLEX, seja através dos dispositivos móveis ou pelo aplicativo desktop para computadores.

                            APLICATIVO ou APP: trata-se da Plataforma FLEX disponibilizada para os dispositivos móveis.

                            LGPD: significa Lei Geral de Proteção de Dados (Lei nº 13.709/2018).


                            CONSENTIMENTO

                            Ao contratar os Serviços, você EXPRESSA E INEQUIVOCADAMENTE CONSENTE COM:

                            a.	estes Termos de Uso;
                            b.	a Política de Privacidade.


                            VIGÊNCIA

                            Estes Termos entrarão em vigor:

                            a.	para novos USUÁRIOS – no momento da ciência e concordância com os termos e condições dispostos a seguir;
                            b.	para USUÁRIOS existentes e visitantes – a partir da data de publicação descrita no cabeçalho destes Termos.


                            TERMOS ADICIONAIS

                            A FLEX poderá editar termos adicionais (“Termos Adicionais” ou “Anexos”), os quais complementarão estes Termos e a Política de Privacidade, sendo que tais Termos Adicionais poderão reger determinados Serviços e/ou Suporte Técnico e serão divulgados a você previamente pela FLEX. Referidos Anexos são complementares e considerados partes integrantes dos presentes Termos. Em caso de conflito entre as disposições aplicáveis, os Termos Adicionais somente prevalecerão sobre estes Termos com relação àquilo que forem específicos.


                            PRIVACIDADE

                            A FLEX presa pela privacidade de todos os USUÁRIOS de seus produtos e/ou serviços e conta com uma Política de Privacidade https://flexuniverse.com.br/politica-e-privacidade que contém todas as diretrizes e apontamentos relacionados ao seu modelo de negócio e sobre como tratam e protegem os dados pessoais e outras informações de seus clientes, colaboradores, parceiros e fornecedores. No que couber, a Política de Privacidade complementa as disposições destes Termos.


                            CADASTRO E DADOS DE ACESSO E CONTA

                            Antes de utilizar os Serviços, você deve, obrigatoriamente, criar um cadastro na Plataforma (“Cadastro”), preenchendo correta e voluntariamente todos os campos do formulário. A informações fornecidas no Cadastro são de inteira e exclusiva responsabilidade do USUÁRIO. Na hipótese de acarretarem danos ou prejuízos de qualquer natureza, a FLEX poderá tomar todas as medidas cabíveis para resguardar seus interesses, os interesses de seus clientes e a integridade dos demais FLEX.

                            Neste Cadastro será solicitado que você escolha uma senha para a sua Conta. Alternativamente, você poderá usar outras credenciais para acessar a Conta (“Dados de Acesso”). Você não deve compartilhar seu o Cadastro, Conta, Dados de Acesso e nem permitir que alguém acesse sua Conta ou faça qualquer outra ação que possa comprometer a segurança da sua Conta.

                            Caso tome conhecimento ou suspeite de violações de segurança, incluindo, mas não limitado a, perda, roubo ou divulgação não autorizada dos Dados de Acesso, você deve notificar imediatamente a FLEX e modificar seus Dados de Acesso.

                            Você é o único responsável pela manutenção da confidencialidade do Cadastro e dos Dados de Acesso e será responsável por todos os usos dos Dados de Acesso autorizados ou não por você, incluindo compras. Você é responsável por tudo o que acontecer por meio da sua Conta.

                            A FLEX se reserva o direito de remover ou recuperar quaisquer nomes de USUÁRIO, a qualquer momento e por qualquer motivo, incluindo, mas não limitado, à alegações de terceiros de que um nome de USUÁRIO viole os direitos de terceiros.


                            SERVIÇOS

                            Os Serviços serão prestados pela FLEX a você, envolvem o desenvolvimento de soluções interativas como games para treinamentos e experiências digitais e imersivas, através de realidade aumentada, realidade virtual, realidade mista e projeção aumentada, proporcionando ao USUÁRIO uma ferramenta interativa para inovar a comunicação utilizando essas ferramentas e, assim, libertando o poder da imaginação.

                            A FLEX se reserva o direito de, a qualquer tempo, modificar ou descontinuar, temporariamente ou permanentemente, as Plataformas e/ou os Serviços ou parte deles, com ou sem notificação. O USUÁRIO concorda que a FLEX não será responsabilizada, nem terá qualquer obrigação adicional, implícita ou explícita, para com o USUÁRIO ou terceiros em razão de qualquer modificação, suspensão ou desativação das Plataformas e/ou dos Serviços.

                            VOCÊ COMPREENDE E DECLARA QUE A FLEX NÃO PRESTA E NÃO ASSEGURA A PRESTAÇÃO DE QUALQUER SUPORTE TÉCNICO OU SUPORTE EXTRA.


                            LICENÇA

                            Concessão de Licença Limitada para o Uso da Plataforma

                            A concessão de licença está condicionada à concordância com os presentes Termos, bem como as demais políticas aplicáveis da FLEX. A FLEX concede a você uma licença não exclusiva, intransferível, não sublicenciável, revogável e limitada para o acesso e uso da Plataforma para seus próprios fins de entretenimento. Você, desde já, concorda em não usar a Plataforma para nenhuma outra finalidade.

                            A licença ora concedida a você se aplica tanto para o uso da Plataforma por dispositivos móveis ou pelo aplicativo desktop da FLEX.

                            Aplicam-se as seguintes restrições ao uso da Plataforma:

                            Você não está autorizado a criar uma Conta ou acessar a Plataforma caso tenha menos de 18 (dezoito) anos de idade; você deve restringir o uso por menores de idade e negar o acesso a crianças menores que 18 (dezoito) anos. Você aceita plena responsabilidade pelo uso não autorizado da Plataforma por menores. Você é responsável pelo uso de seu cartão de crédito ou outro instrumento de pagamento (por exemplo, PayPal) por menores;
                            Você não poderá (e nem tentará) comprar, vender, alugar ou dar sua Conta, criar uma Conta usando identidade ou dados falsos, ou em nome de outrem; você não poderá usar a Plataforma caso já tenha sido removido de ou banido anteriormente de jogar qualquer jogo da FLEX ou utilizar qualquer serviço nosso;
                            Você deve usar sua Conta apenas para fins não comerciais; você não poderá usar a Plataforma para fazer propaganda, solicitar ou transmitir propagandas comerciais, inclusive correntes, mala direta, spam ou mensagens repetitivas ou enganosas a ninguém.

                            A Plataforma, foi desenvolvida e é de propriedade exclusiva da FLEX, que consiste em uma plataforma de comunicação em um ambiente virtual, imersivo e interativo, utilizando-se o formato de aplicativo com estrutura virtual, gamificada para desktop e dispositivos móveis.


                            LIMITAÇÕES DE LICENÇA

                            Qualquer Plataforma em descumprimento a estas Limitações de Licença é estritamente proibido e poderá resultar na revogação imediata de sua licença limitada e responsabilizá-lo por violações da lei.

                            Você concorda em se abster, sob quaisquer circunstâncias, de:

                            Participar de qualquer ato que a FLEX julgue incompatível com o espírito ou propósito da Plataforma ou fazer uso indevido dos serviços de suporte da FLEX;
                            Fazer uso ou participar (direta ou indiretamente) de trapaças, explorar erros, usar softwares de automação, bots, hacks, modificações ou qualquer software de terceiros não autorizado projetado para modificar a Plataforma ou interferir na Plataforma, em jogos e serviços da FLEX ou na experiência de jogo da FLEX;
                            Alterar ou causar a alteração de arquivos que fazem parte a Plataforma ou dos jogos e serviços da FLEX sem o consentimento expresso, por escrito, da FLEX;
                            Interromper, interferir ou, de outro modo, afetar adversamente o fluxo normal da Plataforma ou, ainda, agir de maneira que possa afetar negativamente a experiência de outros usuários ao utilizar a Plataforma e/ou jogar os jogos e serviços da FLEX. Isso inclui a comercialização de vitórias e qualquer outro tipo de manipulação de rankings, aproveitando-se de erros na Plataforma para obter vantagem injusta sobre outros jogadores, bem como qualquer outro ato que intencionalmente viole ou não esteja de acordo com a proposta da Plataforma;
                            Interromper, sobrecarregar, auxiliar, ou contribuir para a interrupção ou sobrecarga de qualquer computador ou servidor (“Servidor”) utilizado para oferecer ou dar apoio à Plataforma ou a ambientes de jogo e serviço da FLEX;
                            Instituir, ajudar, ou se envolver em qualquer tipo de ataque, incluindo, mas não se limitando à distribuição de vírus, ataques de negação de serviço ou outras tentativas de interromper a Plataforma, seu uso ou desfruto por parte de outra pessoa;
                            Tentar obter acesso não autorizado à Plataforma, às Contas registradas para outros ou à computadores, Servidores ou redes conectadas à Plataforma por quaisquer meios que não sejam a interface de usuário fornecida pela FLEX, incluindo, mas não limitado ao, contorno ou modificação, tentativa de burlar ou modificar, incentivar ou auxiliar terceiros ao burlar ou modificar a segurança, tecnologia, dispositivo ou softwares que façam parte da Plataforma;
                            Publicar qualquer informação que seja ofensiva, ameaçadora, obscena, difamatória, caluniosa, ou, ainda de teor questionável ou ofensivo, seja de forma racial, sexual, religiosa, questionável ou ofensiva, ou, ainda, envolver-se em comportamento negativo em curso, tais como, por exemplo, publicando repetidamente informações de forma não solicitada;
                            Publicar informações que contenham nudez, violência excessiva, material ofensivo ou que contenham links para tais conteúdos;
                            Assediar, ofender, insultar ou ferir terceiros, incluindo funcionários da FLEX e representantes do serviço de suporte ao cliente da FLEX, ou tentar praticar tais atos, ou, ainda, defender ou incitar a prática de tais atos;
                            Disponibilizar, por meio da Plataforma, materiais ou informações que infrinjam direitos autorais, marcas, patentes, segredos comerciais, direito de privacidade, direito de publicidade ou outros direitos de terceiros ou de entidades jurídicas ou que personifiquem outra pessoa, incluindo, mas não limitado, a funcionários da FLEX;
                            Fazer engenharia reversa, descompilar, desmontar, decifrar ou tentar obter o Código-fonte de softwares subjacentes ou outras propriedades intelectuais usadas para prestar a Plataforma ou jogos e serviços da FLEX, ou obter informações da Plataforma ou de jogos e serviços da FLEX usando métodos que não sejam expressamente permitidos pela FLEX;
                            Solicitar ou tentar solicitar Informações de Login ou quaisquer outros Dados de Acesso, ou informações pessoais de outros usuários da Plataforma ou de jogos e serviços da FLEX;
                            Coletar ou publicar informações privadas de alguém, incluindo dados de identificação pessoal (seja em forma de texto, imagem ou vídeo), documentos de identificação ou informações financeiras por meio da Plataforma.

                            A FLEX se reserva o direito de determinar quais condutas considera violar as regras de uso ou que, de outra forma, não estejam de acordo ou do espírito destes Termos ou da própria Plataforma. A FLEX se reserva o direito de tomar medidas, como resultado de tais condutas, o que pode incluir o encerramento de sua Conta e a proibição do seu uso da Plataforma, no todo ou em parte.


                            SUSPENSÃO E CESSAÇÃO DA CONTA E DA PLATAFORMA

                            Sem limitação a outras tutelas, a FLEX poderá limitar, suspender, encerrar, modificar ou excluir contas ou o acesso à Plataforma ou a partes da Plataforma em caso de descumprimento destes Termos ou caso a FLEX suspeite que você deixou de cumpri-los, ou, ainda, por uso ilegal ou inadequado da Plataforma, ou pela suspeita de tal uso, sem aviso prévio. Você poderá perder seu nome e seu personagem na Plataforma como resultado do encerramento ou da limitação da Conta, bem como benefícios, privilégios itens adquiridos e itens comprados relacionados ao seu uso da Plataforma, ficando a FLEX isenta de indenizá-lo por tais perdas ou resultados.

                            Sem limitação de nossas demais tutelas, podemos limitar, suspender ou encerrar o serviço e Contas de USUÁRIO ou parte destes, proibir o acesso aos nossos jogos e sites, bem como ao conteúdo, à Plataforma e às ferramentas destes, atrasar ou remover conteúdos hospedados e adotar medidas técnicas e jurídicas para impedir que usuários acessem a Plataforma, caso acreditemos que eles possam acarretar risco ou possíveis responsabilidades legais, violam direitos de propriedade intelectual de terceiros ou ajam de forma inconsistente com o teor ou o escopo de nossos termos ou políticas. Além disso, poderemos, em circunstâncias adequadas e a nosso critério exclusivo, suspender ou encerrar Contas de usuários que sejam infratores reincidentes de direitos de propriedade intelectual de terceiros.

                            A FLEX se reserva o direito de encerrar as contas que estejam inativas por 180 (cento e oitenta) dias ou mais.

                            A FLEX se reserva o direito de parar de oferecer e/ou de manter a Plataforma ou jogo específico ou parte da Plataforma a qualquer momento, sem aviso prévio, o qual resultará no cancelamento automático da sua licença para usar a Plataforma ou parte dela. Nesse caso, a FLEX não terá obrigação de restituir, oferecer benefícios ou outras indenizações para os usuários em relação aos serviços interrompidos.

                            O encerramento de sua Conta poderá incluir a desativação do acesso à Plataforma ou a parte dela, incluindo conteúdos enviados por você ou por terceiros.

                            Você poderá encerrar a sua Conta diretamente na Plataforma https://painel.flexuniverse.com.br.


                            PROPRIEDADE INTELECTUAL

                            Termos Gerais de Propriedade Intelectual

                            A FLEX reterá a propriedade intelectual de tudo aquilo que decorrer da Plataforma e/ou dos Serviços, como planos, desenhos e demais documentos, métodos, patentes, know-how e ferramentas (incluindo softwares), utilizados para (i) desenvolver os serviços constantes destes Termos, e/ou (ii) prestar qualquer serviço que integre a Plataforma.

                            O USUÁRIO não poderá: (i) fazer modificação, cópia, descompilação, publicação ou engenharia reversa da Plataforma, ou desmonte ou, de qualquer outra forma, a redução deles à forma legível por pessoas; (iii) remover quaisquer legendas de propriedade exclusiva ou de direitos autorais constantes da Plataforma, de seus programas e aplicativos, entre outros fornecidos pela FLEX.

                            É de responsabilidade do USUÁRIO a vigilância necessária para assegurar que pessoas não autorizadas não tenham acesso à Plataforma e que todas as pessoas autorizadas e que a ela tenham acesso se abstenham de revelá-la, duplicá-la, reproduzi-la ou distribuí-la de qualquer forma.

                            Os direitos autorais e patrimoniais sobre a Plataforma são e permanecerão sob a titularidade da FLEX. Nada nestes Termos poderá ser interpretado como transferência ou comprometer a transferência de referidos direitos patrimoniais e autorais ao USUÁRIO, sob qualquer forma e a qualquer pretexto.

                            Jogos e Plataforma

                            Todos os títulos e direitos sobre a Plataforma (incluindo, mas não se limitando a, jogos, títulos, códigos de computador, temas, objetos, personagens, nomes de personagens, histórias, diálogos, bordões, conceitos, trabalhos de arte, animações, sons, composições musicais, efeitos audiovisuais, métodos de operação, direitos morais, documentação, transcrições de bate-papo dentro do jogo, informações de perfil de personagens, gravações de jogos jogados por meio do uso de um cliente de jogo da FLEX e dos clientes de jogo da FLEX e softwares de servidor) são de propriedade da FLEX.

                            A FLEX reserva para si todos os direitos, incluindo, mas não se limitando a, direitos de propriedade intelectual ou outros direitos de propriedade relacionados aos jogos e aos serviços.

                            Contas

                            Não obstante disposições em contrário aqui expressas, você reconhece e concorda que não terá propriedade ou outros direitos de propriedade sobre a Conta, bem como reconhece e concorda ainda que todos os direitos sobre a Conta são e serão sempre de propriedade e para o benefício da FLEX.

                            Itens Virtuais

                            A FLEX possui, licencia ou tem os direitos para usar todo o conteúdo que aparece na Plataforma ou em jogos e serviços da FLEX. Não obstante qualquer disposição em contrário aqui disposta, você concorda que não tem direito, título e/ou nenhum outro conteúdo que figura atualmente ou que seja oriundo de qualquer jogo da FLEX, incluindo, mas não se limitando aos, bens virtuais, moeda ou originários de qualquer jogo da FLEX se ganhou em um jogo ou comprado da FLEX, ou quaisquer outros atributos associados a uma Conta ou armazenados na Plataforma.


                            CONTEÚDOS DE USUÁRIO

                            Conteúdo de Usuário

                            “Conteúdo de Usuário” significa quaisquer comunicações, imagens, sons e todos os materiais, dados e informações que você envie ou transmita por meio de um cliente de jogo da FLEX ou da Plataforma, ou que outros usuários enviem ou transmitam, incluindo, mas não se limitando a, textos de bate-papo.

                            Ao transmitir ou enviar Conteúdo de Usuário durante o uso da Plataforma, você afirma, declara e garante que tal transmissão ou envio:

                            É correta e não confidencial ou enganosa;
                            Não viola leis, restrições contratuais ou outros direitos de terceiros, e que você tem permissão de terceiros cujas informações pessoais ou propriedades intelectuais façam parte do Conteúdo de Usuário;
                            É livre de vírus, adware, spyware, worms ou outros códigos maliciosos; e
                            você reconhece e concorda que suas informações pessoais que fazem parte de tal conteúdo sempre serão processadas pela FLEX em conformidade com sua Política de Privacidade.

                            Monitoração de conteúdo pelo Anfitrião

                            O USUÁRIO que estiver utilizado a Plataforma na qualidade de Anfitrião poderá fazer o monitoramento do Conteúdo dos demais usuários que estiverem conectados a reunião, evento, jogo e/ou ao espaço virtual que estiver sob sua organização.

                            Nestes casos o Anfitrião poderá remover os USUÁRIOS que estiverem violando estes Termos, a Política de Privacidade e regras de boa conduta do espaço virtual sob seu comando e, ainda, poderá denunciar tais USUÁRIOS para a FLEX.

                            Monitoração de conteúdo

                            A FLEX não assume responsabilidade pela conduta de USUÁRIO que envie Conteúdo de Usuário e não assume nenhuma responsabilidade de monitorar a Plataforma para localização do Conteúdo ou conduta inadequada. Não monitoramos, nem podemos monitorar todo o Conteúdo de Usuário. Seu uso da Plataforma é realizado por sua conta e risco. Ao usar a Plataforma, você poderá ser exposto a Conteúdo de Usuário que é ofensivo, indecente ou que de outro modo não esteja de acordo com suas expectativas. Você assume todos os riscos associados ao uso de Conteúdos de Usuário disponíveis relativos à Plataforma. A nosso critério, nossos representantes ou nossa tecnologia podem monitorar e/ou registrar a sua interação com a Plataforma ou suas comunicações, incluindo, mas não se limitando a, textos de bate-papos, quando você usar a Plataforma.

                            Ao aderir a estes Termos, você consente irrevogavelmente tal monitoração e registro. Você reconhece e concorda que não tem expectativas de privacidade acerca da transmissão de Conteúdo de Usuário, incluindo, mas não se limitando a, textos de bate-papos e/ou comunicações de voz.

                            A FLEX se reserva o direito, a seu exclusivo critério, de revisar, monitorar, proibir, editar, apagar, desabilitar o acesso ou indisponibilizar Conteúdo de Usuário, por qualquer motivo ou sem motivo, a qualquer momento.

                            Se em algum momento, a FLEX escolher, a seu critério exclusivo, monitorar a Plataforma, a FLEX não assume responsabilidade pelo Conteúdo de Usuário e não assume obrigação de modificar ou remover Conteúdo de Usuário inadequado. Portanto, nós temos o direito, mas não a obrigação, a nosso critério exclusivo, de editar, recusar a publicação, ou remover Conteúdo de Usuário.


                            USO DE INFORMAÇÕES POR OUTROS MEMBROS DA PLATAFORMA

                            Discurso Público

                            A Plataforma pode incluir diversos fóruns, blogs e recursos de bate-papo nos quais você pode postar Conteúdo de Usuário, inclusive suas observações e comentários sobre temas designados.

                            A FLEX não pode garantir que outros membros não usarão as ideias e informações que você compartilhar. Portanto, caso você tenha ideias ou informações que gostaria de manter confidenciais e/ou não deseja que outros usem, não as publique na Plataforma. A FLEX não se responsabiliza por avaliar, usar ou compensá-lo por ideias ou informações que você pode optar por enviar.

                            Responsabilidade pelo Conteúdo Próprio

                            Você é o único responsável pelas informações que você posta na Plataforma, por meio da Plataforma e que você fornece para os outros usuários. A FLEX pode rejeitar, recusar a publicação ou excluir Conteúdo de Usuário sem motivo ou por qualquer motivo que, a critério exclusivo da FLEX, viole os Termos e/ou a Política de Privacidade.

                            Sua Licença para a FLEX

                            Pelo presente documento, você concede à FLEX uma licença irrevogável, perpétua, transferível, integralmente paga, isenta de royalties, e mundial (inclusive o direito de sublicenciar e ceder a terceiros) e o direito de copiar, reproduzir, corrigir, adaptar, modificar, criar trabalhos derivados, fabricar, comercializar, publicar, distribuir, vender, licenciar, sublicenciar, transferir, alugar, transmitir, exibir publicamente, executar publicamente, ou fornecer acesso eletronicamente, transmitir por radiodifusão, comunicar ao público por telecomunicações, exibir, executar, armazenar em memória de computador, e usar e praticar, de qualquer forma, seus Conteúdo de Usuário, bem como todas as obras modificadas e derivadas dos mesmos em relação à nossa Plataforma, incluindo a comercialização e promoções do Plataforma.

                            Pelo presente documento, você também concede à FLEX o direito de autorizar terceiros a exercerem os direitos concedidos à FLEX previstos nestes Termos. Além disso, você concede à FLEX o direito incondicional e irrevogável de uso e aproveitamento do seu nome, da sua imagem e de outras informações ou materiais incluídos em Conteúdo de Usuário relacionado ao Conteúdo de Usuário, sem qualquer obrigação para com você. Exceto no que for proibido por lei, você renuncia a direitos de atribuição e/ou direitos morais que você possa ter sobre seus Conteúdos de Usuário, independentemente de tais Conteúdos de Usuário ter sido alterados ou modificados de qualquer maneira. A FLEX não reivindica direitos de propriedade sobre o seu Conteúdo de Usuário e não há nada expresso nestes Termos que tenha o objetivo de restringir os direitos que você porventura possa ter a respeito do uso e aproveitamento do seu Conteúdo de Usuário. A FLEX não tem obrigação de monitorar ou fazer valer os seus direitos de propriedade intelectual sobre o seu Conteúdo de Usuário

                            Interações do Usuário

                            Você é o único responsável por suas interações com outros usuários da Plataforma e com outras partes com as quais você interaja por meio da Plataforma e/ou dos jogos e serviços da FLEX.

                            A FLEX se reserva o direito, mas não a obrigação, de se envolver de qualquer modo com tais disputas. Você cooperará de forma plena com a FLEX para investigar atividades suspeitas, ilegais, fraudulentas ou inadequadas, incluindo, mas não limitado a concessão de acesso à FLEX a partes da sua Conta protegidas por senha.

                            Em caso de litígio com um ou mais usuários, você nos exonera (e a nossos diretores, agentes, subsidiárias, sociedades conjuntas e funcionários) de ações, demandas e indenizações (por dano direto e indireto) de qualquer tipo e natureza, conhecidos e desconhecidos, decorrentes ou de qualquer modo relacionados com tais disputas.


                            TERMOS DE COMPRA E TAXAS

                            Compras

                            Na Plataforma, você pode comprar, com dinheiro “do mundo real”, uma licença limitada, pessoal, intransferível, não sublicenciável e revogável para usar: (a) “moeda virtual”, incluindo, mas não se limitando a, dinheiro virtual ou diamantes, tudo para utilização em jogos e serviços da FLEX; (b) “itens do jogo virtuais” (juntamente com a “moeda virtual”, “Itens Virtuais”) e (c) outros produtos ou serviços (“Mercadorias”). Você está apenas autorizado a comprar Itens Virtuais da FLEX ou de nossos parceiros autorizados por meio da Plataforma e não por qualquer outro meio.

                            A FLEX pode gerenciar, regular, controlar, modificar ou eliminar Itens Virtuais e/ou Mercadorias a qualquer tempo, com ou sem aviso prévio. A FLEX não tem responsabilidade para com você ou para com terceiros, caso a FLEX exerça esses direitos.

                            A transferência de Itens Virtuais e Mercadoria é proibida, exceto quando autorizada expressamente na Plataforma. A menos que expressamente autorizado na Plataforma, você não poderá vender, comprar, trocar ou, de outro modo, transferir Itens Virtuais ou Mercadorias a outras pessoas físicas ou jurídicas e incluindo nenhuma tentativa de algum item supracitado, incluindo, mas não se limitando à, FLEX, a outros Usuários ou a terceiros.

                            Todas as compras e resgate de Itens Virtuais feitas por meio da Plataforma são finais e não reembolsáveis.

                            O fornecimento de Itens Virtuais para uso em jogos é um serviço prestado pela FLEX que começa imediatamente após aceitação de sua compra pela FLEX.

                            Pagamento de Taxas

                            Você concorda em pagar todas as taxas e impostos incorridos por você ou por outros por meio de uma Conta registrada para você. A FLEX poderá rever os preços para os produtos e serviços oferecidos por meio da Plataforma a qualquer momento.

                            Você reconhece que a FLEX está isenta da obrigação de reembolso por qualquer motivo e que você não receberá dinheiro ou outras compensações por Itens Virtuais não utilizados quando uma conta for encerrada, seja tal encerramento voluntário ou não, ou ainda se a FLEX decidir pelo encerramento da Plataforma.


                            ATUALIZAÇÕES DA PLATAFORMA

                            Você entende que a Plataforma se encontra em evolução. A FLEX pode requerer que você aceite atualizações da Plataforma e dos jogos e serviços da FLEX instalados em seu dispositivo ou computador. Você reconhece e concorda que a FLEX pode atualizar a Plataforma, os jogos e serviços da FLEX a qualquer momento, notificando-o ou não. Pode ser necessário que você atualize softwares de terceiros ao longo do tempo a fim de receber a Plataforma e acessar jogos e serviços da FLEX.


                            RENÚNCIA DE GARANTIAS

                            SEM LIMITAÇÃO DE RESPONSABILIDADE DA FLEX, PREVISTA NO ITEM ABAIXO, A PLATAFORMA É FORNECIDA “COMO SE ENCONTRA” E “CONFORME DISPONÍVEL” PARA SEU USO, SEM GARANTIAS DE QUALQUER TIPO, EXPRESSAS OU IMPLÍCITAS, INCLUINDO, MAS NÃO SE LIMITANDO AS, GARANTIAS DE COMERCIALIZAÇÃO, ADAPTAÇÃO PARA UM DETERMINADO FIM, TÍTULO, NÃO VIOLAÇÃO, AS QUAIS SÃO AS DECORRENTES DO DECURSO DO TRATAMENTO DE COMÉRCIO OU DO SEU USO DE NEGOCIAÇÃO OU USO COMERCIAL. A FLEX NÃO GARANTE QUE VOCÊ TENHA CONDIÇÕES DE ACESSAR OU USAR A PLATAFORMA NOS MOMENTOS OU LOCAIS DE SUA ESCOLHA, QUE A PLATAFORMA NÃO SEJA INTERROMPIDA OU ISENTA DE ERROS, QUE DEFEITOS SERÃO CORRIGIDOS, OU QUE O JOGO, OS SERVIÇOS OU A PLATAFORMA SEJAM LIVRES DE VÍRUS OU DE OUTROS COMPONENTES NOCIVOS.

                            Algumas jurisdições não permitem a exclusão de algumas garantias. Deste modo, algumas das renúncias mencionadas acima podem não se aplicar a você.


                            LIMITAÇÃO DE RESPONSABILIDADE; TUTELA ÚNICA E EXCLUSIVA; INDENIZAÇÃO

                            Até o limite máximo permitido por lei, a FLEX não será responsável para com você por danos indiretos, incidentais, consequentes, especiais, punitivos ou similares, incluindo, mas não se limitando à, perda de receita, lucros cessantes, perda de dados ou interrupção de negócios ou outras perdas intangíveis, decorrentes destes Termos ou da Plataforma em si, ou relacionados de qualquer forma aos mesmos, seja com base em contrato, ato ilícito, ou outras teorias legais, e independentemente de a FLEX ter sido avisada da possibilidade de tais danos.

                            A FLEX não será responsável por mais do que o valor pago à FLEX de acordo com os presentes Termos, no período de 6 (seis) meses imediatamente anteriores à data na qual seu direito foi invocado.

                            Você reconhece e concorda que caso você não tenha pagado nada à FLEX durante tal intervalo de tempo, sua única solução (e responsabilidade exclusiva da FLEX) para qualquer litígio com a FLEX é parar de utilizar a Plataforma e cancelar sua Conta. Em particular, nada nestes Termos afetará os direitos legais de consumidores ou excluirá ou restringirá responsabilidades por morte ou danos pessoais resultantes de culpa ou fraude por parte da FLEX.


                            LINKS DE TERCEIROS

                            Certos produtos, conteúdos e serviços disponíveis na Plataforma podem incluir materiais de terceiros. Os links de terceiros na Plataforma podem te direcionar para sites de terceiros que não são afiliados a nós. Não nos responsabilizamos por examinar ou avaliar o conteúdo ou precisão. Não garantimos e nem temos obrigação ou responsabilidade por quaisquer materiais ou sites de terceiros, ou por quaisquer outros materiais, produtos ou serviços de terceiros.

                            Não somos responsáveis por quaisquer danos ou prejuízos relacionados com a compra ou uso de mercadorias, serviços, recursos, conteúdo, ou quaisquer outras transações feitas em conexão com quaisquer sites de terceiros. Por favor, revise com cuidado as políticas e práticas de terceiros e certifique-se que você as entende antes de efetuar qualquer transação. As queixas, reclamações, preocupações ou questões relativas a produtos de terceiros devem ser direcionadas ao terceiro.


                            PROTEÇÃO DE DADOS

                            O USUÁRIO concorda expressamente que concede à FLEX o consentimento prévio e expresso para que esta colete, trate, armazene e compartilhe os dados de seus sócios, prepostos e colaboradores e que obteve o consentimento destes expressa e inequivocamente, para: (i) possibilitar o acesso aos Serviços da FLEX; (ii) envio de informativos e newsletters; (iii) responder as solicitações dos usuários; (iv) pesquisa e análise, a fim de aprimoramento da tecnologia e serviços de remarketing; (v) prevenção,  constatação, mitigação e investigação  de atos potencialmente ilícitos e/ou brechas de segurança na Plataforma e Serviços; (vi) avaliação e gestão de riscos; e (vii) promoção de serviços de atendimento ao USUÁRIO.

                            O uso dos dados e segurança destes é único e exclusivo da FLEX, sendo a única responsável pela guarda dos dados e informações disponibilizados por vocês, obrigando-se a FLEX a:
                            Tratar e usar os dados pessoais nos termos legalmente permitidos, em especial recolhendo, registando, organizando, conservando, consultando ou transmitindo-os, apenas e somente nos casos em que o Usuário tenha dado o consentimento inequívoco ou nos restantes legalmente previstos;
                            Tratar os dados de modo compatível com as finalidades para os quais tenham sido recolhidos;
                            Conservar os dados apenas durante o período necessário à prossecução das finalidades da recolha ou do tratamento posterior, garantindo a sua confidencialidade;
                            Informar imediatamente ao USUÁRIO, devendo prestar toda a colaboração necessária a qualquer investigação que venha a ser realizada, caso exista alguma quebra de segurança, ou suspeita, independentemente de colocar ou não em causa a segurança e integridade dos Dados Pessoais;
                            Assegurar que os respetivos colaboradores ou os prestadores de serviços externos contratados pela FLEX, que venham a ter acesso a dados pessoais dos Usuários no contexto da Plataforma, cumpram as disposições legais aplicáveis em matéria de proteção de dados pessoais, designadamente, não cedendo ou divulgando tais dados pessoais a terceiros, nem deles fazendo uso para quaisquer fins que não os estritamente consentidos pelos respetivos Usuários ou, se aplicável, ser o seu processamento objeto de notificação ou de pedido de autorização à Comissão Nacional de Proteção de Dados.

                            A FLEX declara que ao compartilhar os dados, sempre cumprirá com toda lei e regra aplicável à coleta, segurança e compartilhamento de qualquer dado e/ou informação do USUÁRIO.

                            As Partes se comprometem a atuar em cumprimento à Lei nº 13.709/2018 (“LGPD”), sendo cada uma responsável individualmente pelo atendimento das determinações que a LGPD ou os órgãos reguladores lhes impõem, comprometendo-se a:

                            comunicar, por escrito e em tempo razoável, as solicitações encaminhadas por titulares de dados pessoais, informando todo e qualquer dado pessoal que possuir em seu banco de dados que seja de propriedade do titular;
                            adotar medidas tecnológicas de governança e segurança de dados pessoais para garantir sua proteção, incluindo, mas não se limitando a adoção de técnicas de “pseudonimização” e “anonimização”, sempre que possível, criptografia de dados armazenados em seus sistemas, bem como técnicas de proteção contra a destruição (acidental ou ilícita), perda, alteração, acesso não autorizado, comunicação ou difusão dos dados pessoais; e
                            registrar atividades realizadas nos seus sistemas/ambientes utilizados pelas Partes que tratem de dados pessoais, observando todas as diretrizes exigidas pela autoridade competente;

                            As Partes reconhecem que a propriedade dos dados pessoais eventualmente tratados em decorrência da Conta do USUÁRIO é de propriedade dos seu titular, sendo vedada qualquer disposição no sentido de adquirir ou transferi-la.

                            O acesso aos dados pessoais será limitado aos empregados da FLEX que tiverem necessidades comerciais e legítimas para acessá-los.

                            Fica desde já estabelecido que, no caso de uma Parte ser responsabilizada pelo pagamento de qualquer indenização inerente ao descumprimento das regras previstas na LGPD pela outra Parte, a parte infratora arcará com a restituição de eventuais valores desembolsados pela parte inocente, ficando desde já autorizado o ingresso da competente ação de regresso.

                            Em caso de eventual falha na segurança dos dados, a FLEX imediatamente notificará o USUÁRIO por meio dos mecanismos de comunicação fornecidos no Cadastro, adotando, ainda, todas as medidas de segurança aplicáveis e conforme a sua Política de Privacidade.

                            O USUÁRIO poderá requerer a exclusão de seus dados do banco de dados da FLEX, basta encaminhar e-mail para o endereço; [inserir e-mail].


                            DISPOSIÇÕES GERAIS

                            CUMPRIMENTO DA LEGISLAÇÃO APLICÁVEL. As Partes concordam que cumprirão todas as leis, normas, regulamentos e códigos federais, estaduais e municipais aplicáveis ao cumprimento de suas obrigações em virtude destes Termos, incluindo a obtenção de licenças e certificados, quando se fizer necessário.

                            PODERES DOS REPRESENTANTES. As Partes declaram que os signatários deste têm poderes para assumir obrigações em nome das respectivas Partes, sob pena de responderem civil e criminalmente.

                            INDEPENDÊNCIA DAS PARTES. As Partes são contratantes independentes segundo estes Termos e nada previsto neste documento deve ser interpretado de forma a criar uma relação de trabalho, sociedade, joint venture, franquia, agência ou representação entre as Partes.

                            CESSÃO. É vedado ao USUÁRIO ceder estes Termos e/ou seus direitos a terceiros, total ou parcialmente, ou dá-lo em garantia, sem o consentimento prévio e expresso da FLEX.

                            NÃO RENÚNCIA. A opção de qualquer das Partes em não exercer qualquer direito que lhe seja garantido sob estes Termos não constituirá renúncia dos seus direitos em exercê-los futuramente, nem importará em novação ou alteração contratual, a qual só poderá ser validamente efetuada mediante a assinatura de aditivo a estes Termos.

                            CASO FORTUITO OU FORÇA MAIOR. Nenhuma das Partes poderá ser considerada como inadimplente de suas obrigações contratuais se tal fato for resultante de caso fortuito ou força maior.

                            NOTIFICAÇÕES E COMUNICAÇÕES. Quaisquer notificações e/ou comunicações formais entre as Partes, exigidas por estes Termos, devem ser efetuadas por escrito, nos endereços físicos ou eletrônicos fornecidos na Plafatorma e/ou no app ou, ainda, por meio do WhatsApp®, a exclusivo critério da FLEX, e serão consideradas como efetivadas no momento da confirmação da recepção/leitura pela Parte destinatária da comunicação. Qualquer comunicação/notificação por e-mail para a FLEX deverá ser enviada para dpo@flexinterativa.com.br.

                            Em caso de qualquer notificação e/ou comunicação via correio eletrônico feita nos endereços fornecidos na Plataforma e/ou no APP, não havendo confirmação de recebimento/leitura, será a notificação e/ou comunicação considerada efetivamente recebida após 5 (cinco) dias úteis a contar do seu envio.

                            É obrigação de cada Parte alterar na Plataforma e no app qualquer mudança em seus endereços, sejam físicos ou eletrônicos. Até que seja feita essa alteração, serão válidos e eficazes os avisos, comunicações, notificações e interpelações enviadas para os endereços constantes da Plataforma.

                            SUCESSÃO. As Partes se obrigam por si, seus herdeiros e sucessores a qualquer título, ao fiel cumprimento destes Termos.

                            LEI APLICÁVEL E RESOLUÇÃO DE CONTROVÉRSIAS

                            Qualquer controvérsia decorrente ou relacionada aos presentes Termos, mesmo após sua eventual rescisão, que não seja resolvida amigavelmente entre as Partes, deverá ser solucionada por arbitragem, de acordo com as regras de arbitragem da Vamos Conciliar (disponível em http://www.vamosconciliar.com), vigentes na data na qual o pedido de arbitragem for apresentado, com aplicação subsidiária da Lei Federal n. 9.307, de 23 de setembro de 1996 e do Código de Processo Civil Brasileiro. Caso a Vamos Conciliar não esteja mais em funcionamento, então a controvérsia será submetida à Justiça Comum.

                            O local da arbitragem será a cidade de São Paulo, Estado de São Paulo, Brasil e o idioma da arbitragem será Português.

                            A decisão dos árbitros será final e vinculante.

                            As custas e despesas decorrentes do procedimento de arbitragem serão suportadas pela parte vencida.

                            É garantido às Partes o direito de buscar assistência judicial: (i) para compelir a arbitragem; (ii) para obter medidas incidentais protetivas de direitos, anteriormente à instituição da arbitragem, e qualquer ação nesse sentido não poderá ser interpretada como renúncia ao procedimento arbitral pelas Partes; (iii) para executar a decisão dos árbitros, incluindo a sentença arbitral.

                            Caso quaisquer das Partes busquem a prestação jurisdicional, fica eleito, desde já como competente o juízo do Foro da Comarca de São Paulo, Estado de São Paulo, para a solução de qualquer questão originada destes Termos, com renúncia expressa de qualquer outro, por mais privilegiado que seja.

                            Havendo quaisquer dúvidas ou sugestões em relação aos presentes Termos, entre em contato com a FLEX por meio dos mecanismos de comunicação disponíveis em https://flexinterativa.com.br.
                        </textarea>
                    </Container>
                </Container>
            </div>
        </motion.div>
    )
}

export default PrivacyPolitics