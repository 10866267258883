
import React, { useCallback, useEffect, useState } from 'react'
import { motion, useIsPresent } from "framer-motion";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Helmet } from "react-helmet-async"
import Gallery from "react-photo-gallery";
import { Container } from 'react-bootstrap'
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { useIntro } from '../context/introContext'
import GalleryLoader from '../components/GalleryLoader'
import { projectsList, category } from '../components/Json'
import { TextWithLineBreaks } from '../components/functions'
import { FooterFixed } from '../components/Footer'
import { useSearchContext } from '../context/searchContext'
import useDrag from '../components/useDrag'
import {
    faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Swiper, SwiperSlide } from 'swiper/react';
import "react-horizontal-scrolling-menu/dist/styles.css";
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';

const Projects = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams()
    const [selectCategory, setSelectCategory] = useState({ search: '', key: '' })
    const [loader, setLoader] = useState(true)
    const { skipIntro, setSkip } = useIntro();
    const { searchQuery, filteredResults, setFilteredResults } = useSearchContext();
    const isPresent = useIsPresent();

    function handleSelectCategory(value, i) {
        setLoader(true)
        if (i === selectCategory.key) {
            navigate("/projetos", { replace: true })
            setSelectCategory({ search: '', key: '' });
        } else {
            navigate(`/projetos?categoria=${value}`, { replace: true })
            setSelectCategory({ search: value, key: i });
        }
    }

    // NOTE: for drag by mouse
    var _a = useDrag(),
        dragStart = _a.dragStart,
        dragStop = _a.dragStop,
        dragMove = _a.dragMove;

    var handleDrag = function (_a) {
        var scrollContainer = _a.scrollContainer;
        return function (ev) {
            return dragMove(ev, function (posDiff) {
                if (scrollContainer.current) {
                    scrollContainer.current.scrollLeft += posDiff;
                }
            });
        };
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const categoria = searchParams.get('categoria');
        let filtered = projectsList;
        if (id) {
            filtered.forEach(element => {
                if (element.link_param === id) {
                    filtered = element
                }
            });
        } else {
            if (categoria && selectCategory.search === '') {
                const index = category.findIndex(obj => obj.search === categoria);
                if (index !== -1) {
                    setSelectCategory({ search: categoria, key: index });
                }
            }
            if (selectCategory.key !== '') {
                filtered = filtered.filter(project => {
                    console.log(project)
                    const searchableFields = [
                        project.category_id,
                    ];
                    return searchableFields.some(field => field.toString().includes(selectCategory.key));
                });
            }
            if (searchQuery.trim() !== '') {
                //Split words ??

                //Search for each word
                const lowercaseQuery = cleanUpSpecialChars(searchQuery.toLowerCase())
                filtered = filtered.filter(project => {
                    const searchableFields = [
                        cleanUpSpecialChars(project.title.toLowerCase()),
                        cleanUpSpecialChars(project.category.toLowerCase()),
                    ];
                    return searchableFields.some(field => field.includes(lowercaseQuery));
                });
            }
        }
        setFilteredResults(filtered);
        if (!skipIntro) {
            setTimeout(() => {
                setSkip()
            }, 500);
        }
    }, [searchQuery, projectsList, selectCategory, id]);
    console.log(id)
    if (id) {
        return <ShowProject loader={loader} setLoader={setLoader} filteredResults={filteredResults}></ShowProject>
    }

    return (
        <>
            <motion.div className="App" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Container fluid className="projectContainer">
                    <div className="divider gradient mt-5"></div>
                    <div className="menuScroll" onMouseLeave={dragStop}>
                        <ScrollMenu
                            onMouseLeave={dragStop}
                            onWheel={onWheel}
                            onMouseDown={dragStart}
                            onMouseUp={dragStop}
                            onMouseMove={handleDrag}
                        >
                            {category.map((option, i) => (
                                <button key={'button' + i} className={`projectImageCard buttonMenu ${selectCategory.key === i ? ('active') : ('')}`} onClick={() => handleSelectCategory(option.search, i)}>{option.title}<figcaption></figcaption></button>
                            ))}
                        </ScrollMenu>
                    </div>
                    <GalleryContainer loader={loader} setLoader={setLoader} filteredResults={filteredResults}></GalleryContainer>
                    <FooterFixed></FooterFixed>
                </Container>
            </motion.div >
            {!skipIntro && <motion.div
                initial={{ scaleX: 1 }}
                animate={{
                    scaleX: 0, transition: { duration: 0.5, ease: "circOut" }, transitionEnd: {
                        display: "none",
                    },
                }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
            }
        </>
    )
}

const ShowProject = ({ filteredResults }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const navigate = useNavigate();

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const imageRenderer = useCallback(
        (props) => (
            <SelectedImage
                props={props}
                type={2}
                openLightbox={openLightbox}
            />
        ),
        []
    );

    function truncateDescription(description, maxWords) {
        console.log(description)
        const wordsArray = description.split(/\s+/);
        if (wordsArray.length > maxWords) {
            return wordsArray.slice(0, maxWords).join(" ") + '...';
        }
        return description;
    }

    if (filteredResults.id) {
        return (
            <>
                <motion.div className="projects" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div className="App">
                        <Container fluid className="position-relative overflow-hidden">
                            <div className="divider gradient mt-5"></div>
                            <Container fluid="sm">
                                <div className="col mt-4">
                                    <p className="h4 projectTitle">
                                        <FontAwesomeIcon icon={faArrowLeft} className="iconsHover" onClick={() => navigate('/projetos')} style={{ marginTop: '0.125rem', marginRight: '1rem' }} />
                                        {filteredResults.title}
                                        <div></div>
                                    </p>
                                    <p className="text-center">
                                        {filteredResults.category}
                                    </p>
                                </div>
                                {filteredResults.video && filteredResults.video.length > 0 && <Videos videos={filteredResults.video} />}
                                {filteredResults.media && filteredResults.media.length > 0 && <Gallery photos={filteredResults.media} renderImage={imageRenderer} direction={"column"} className="my-auto" />}
                                <ModalGateway>
                                    {viewerIsOpen ? (
                                        <Modal onClose={closeLightbox}>
                                            <Carousel
                                                currentIndex={currentImage}
                                                views={filteredResults.media.map(x => ({
                                                    ...x,
                                                    srcset: x.srcSet,
                                                    caption: x.title
                                                }))}
                                            />
                                        </Modal>
                                    ) : null}
                                </ModalGateway>
                            </Container>
                        </Container>
                        <Container fluid className="px-0 mx-0">
                            <div class="module">
                                <div class="stripe-3"></div>
                            </div>
                        </Container>
                        <Container fluid className="position-relative overflow-hidden">
                            <Container fluid="sm" className="mb-5">
                                <h1 className="text-main-color mt-5 mb-3">Descrição do projeto</h1>
                                <p>{<TextWithLineBreaks text={filteredResults.description}></TextWithLineBreaks>}</p>
                                <div className="row mt-4">
                                    {filteredResults.partners && <p className="opacity-text">
                                        Parceiros: {filteredResults.partners}
                                    </p>}
                                    {filteredResults.year && <p className="opacity-text">
                                        Ano: {filteredResults.year}
                                    </p>}
                                </div>
                            </Container>
                            <Container fluid className="position-relative overflow-hidden mb-4">
                                <h1 className="text-main-color text-center mt-4 mb-3">Projetos relacionados</h1>
                                <RelatedProjects filteredResults={filteredResults}></RelatedProjects>
                            </Container>
                        </Container>
                        <FooterFixed></FooterFixed>
                    </div>
                </motion.div >
            </>
        );
    }

}

const Videos = ({ videos }) => {
    console.log(videos)
    return (
        <>
            {videos.map((option, i) => (
                <video key={'video' + i} controls className="projectVideoContainer" src={option}></video>
            ))}
        </>
    )
}

const RelatedProjects = ({ filteredResults }) => {
    const navigate = useNavigate();
    const [relatedProjects, setRelatedProjects] = useState(false)

    useEffect(() => {
        let filtered = projectsList
        filtered = filtered.filter(project => {
            const searchableFields = [
                project.category_id,
            ];
            return searchableFields.some(field => field.toString().includes(filteredResults.category_id));
        });
        setRelatedProjects(filtered)
    }, [filteredResults.category_id])

    if (!relatedProjects) {
        return ""
    }

    return (
        <>
            <Swiper
                slidesPerView={'auto'}
                centeredSlides={false}
                centerInsufficientSlides={true}
                spaceBetween={15}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="relatedProjectsSwipe"
            >
                {relatedProjects.map((item, i) => (
                    <SwiperSlide className="cursor-pointer" onClick={() => navigate(item.link)}><img src={item.src} alt={item.link_param}></img></SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}

const SelectedImage = ({ props, type, openLightbox }) => {
    const navigate = useNavigate();

    function handlePhotoClick(link) {
        navigate(link)
    }

    if (type === 1) {
        return (
            <>
                <motion.div className="projectImageP" style={{ top: props.top, left: props.left, height: props.photo.height, width: props.photo.width }} onClick={() => handlePhotoClick(props.photo.link)} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <figure className="projectImageCard">
                        <img
                            alt={props.photo.title}
                            {...props.photo}
                        />
                        <figcaption>
                            <div className="projectCardShadow">
                                <h4>{props.photo.title}</h4>
                                <h6>{props.photo.category} </h6>
                            </div>
                        </figcaption>
                    </figure>
                </motion.div>
            </>
        )
    } else {
        return (
            <>
                <motion.div className="projectImageP" style={{ top: props.top, left: props.left, height: props.photo.height, width: props.photo.width }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={() => openLightbox(props.index)}>
                    <figure className="projectImageCard">
                        <img
                            alt={props.photo.title}
                            {...props.photo}
                        />
                        <figcaption></figcaption>
                    </figure>
                </motion.div>
            </>
        )
    }


}

const GalleryContainer = ({ loader, setLoader, filteredResults }) => {

    const imageRenderer = useCallback(
        (props, i) => (
            <SelectedImage
                key={'image' + props.top + props.left}
                props={props}
                type={1}
            />
        ),
        []
    );

    useEffect(() => {
        setTimeout(() => {
            setLoader(false)
        }, 2000);

    }, [loader]);

    if (loader) {
        return (
            <>
                <GalleryLoader></GalleryLoader>
            </>
        )
    }

    return (
        <>
            {filteredResults.length > 0 ? (
                <Gallery photos={filteredResults} renderImage={imageRenderer} direction={"column"} className="my-auto" />
            ) : (
                <motion.div className="my-auto text-center" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <p>Nenhum projeto encontrado...</p>
                </motion.div>
            )}
            {/* {filteredResults.length < 15 ?
                (
                    <>
                        <motion.h6 className="text-center mt-5 cursor-pointer" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                            ...
                        </motion.h6>
                    </>
                ) : ("")
            } */}
        </>
    )
}

function cleanUpSpecialChars(str) {
    return str
        .replace(/[ÀÁÂÃÄÅ]/g, "A")
        .replace(/[àáâãäå]/g, "a")
        .replace(/[ÈÉÊË]/g, "E")
        .replace(/[èéêë]/g, "e")
        .replace(/[ÌÍÎÏ]/g, "I")
        .replace(/[ìíîï]/g, "i")
        .replace(/[ÒÓÔÕÖØ]/g, "O")
        .replace(/[òóôõöø]/g, "o")
        .replace(/[ÙÚÛÜ]/g, "U")
        .replace(/[ùúûü]/g, "u")
        .replace(/[^a-z0-9]/gi, ''); // final clean up
}

function onWheel(apiObj, ev) {
    var isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }
    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}

export default Projects



















