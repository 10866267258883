
import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import { ReactSession } from 'react-client-session';
import { Link, useLocation } from "react-router-dom";
import { useLanguage } from '../context/languageContext'
import { useSearchContext } from '../context/searchContext'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { redirectURL } from '../components/functions'
import {
    faRightToBracket,
    faSearch,
    faCircleXmark,
    faClose
} from "@fortawesome/free-solid-svg-icons";
import {
    faWhatsapp
} from '@fortawesome/free-brands-svg-icons';
import logo from '../assets/images/logoFlex.png';

const NavbarX = ({ onScrollY }) => {
    const [showSearch, setShowSearch] = useState(1)
    const [urlParam, setUrlParam] = useState(1)
    const [scrollHeight, setScrollHeight] = useState(0);
    const [showWhatsApp, setShowWhatsApp] = useState(true)
    const { language, setLanguage } = useLanguage();
    const { searchQuery, setSearchQuery } = useSearchContext();
    const { pathname } = useLocation();

    const handleScroll = () => {
        const currentScrollHeight = window.scrollY;
        setScrollHeight(currentScrollHeight);
    };

    function handleFloatButtonClose() {
        setShowWhatsApp(false)
    }

    function handleCloseSearch() {
        setShowSearch(1)
        setSearchQuery('')
    }

    function changeLanguage(lg) {
        setLanguage(lg)
        ReactSession.setStoreType("localStorage");
        ReactSession.set('flex-language', lg)
    }

    useEffect(() => {
        let url = pathname.split("/");
        setUrlParam('/' + url[1])
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pathname])

    return (
        <>
            <motion.nav className={`navbar position-fixed w-100 shadow ${scrollHeight > 60 ? ('background-main-color') : ('')}`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <div className="ps-4 navParent">
                    <ul className={`navbar-language d-flex`}>
                        <li className="nav-item cursor-pointer">
                            <h6 className={`px-2 mb-0 me-2 text-click text-click-underline-opening ${language === 'pt' ? 'active' : ''}`} onClick={() => changeLanguage('pt')}>PT</h6>
                        </li>
                        <li className="nav-item cursor-pointer">
                            <h6 className={`px-2 mb-0 me-1 text-click text-click-underline-opening ${language === 'en' ? 'active' : ''}`} onClick={() => changeLanguage('en')}>EN</h6>
                        </li>
                    </ul>
                </div>
                <div className="text-center m-auto navParent">
                    <img className="App-logo" src={logo} alt="Logomarca" onClick={() => window.location.replace('/projetos')}></img>
                </div>
                <div className="position-relative toolbar navParent">
                    <AnimatePresence mode="wait" initial={false}>
                        {showSearch === 1 && <motion.div key={'motion01'} className="ms-auto overflow-hidden" initial={{ width: '0px' }} animate={{ width: '100%' }} exit={{ width: '0px' }} transition={{ duration: 0.5,type: 'ease' }}>
                            <ul className={`navbar-pages search-bar d-flex`}>
                                <li className="nav-item">
                                    <Link className={`text-hover text-click text-click-underline-opening ${urlParam === '/projetos' ? 'active' : ''}`} to={"/projetos"}>Projetos</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`text-hover text-click text-click-underline-opening ${urlParam === '/tecnologias' ? 'active' : ''}`} to={"/tecnologias"}>Tecnologias</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`text-hover text-click text-click-underline-opening ${urlParam === '/sobre' ? 'active' : ''}`} to={"/sobre"}>Sobre</Link>
                                </li>
                                <li className="nav-item" onClick={() => setShowSearch(2)}>
                                    <FontAwesomeIcon icon={faSearch} size="lg" className="mx-2 iconsHoverNav" />
                                </li>
                                <li className="nav-item">
                                    <Link className={`text-click pe-0`} target="_blank" rel="noopener noreferrer" to={"https://painel.flexinterativa.com.br"}>
                                        <FontAwesomeIcon icon={faRightToBracket} size="lg" className="iconsHoverNav" />
                                    </Link>
                                </li>
                            </ul>
                        </motion.div>}
                        {showSearch === 2 && <motion.div key={'motion02'} className="ms-auto overflow-hidden" initial={{ width: '0px' }} animate={{ width: '100%' }} exit={{ width: '0px' }} transition={{ duration: 0.5, type: 'ease' }}>
                            <div className={`toolbar__search d-flex align-items-center justify-content-end`}>
                                <FontAwesomeIcon icon={faCircleXmark} size="lg" className="toolbar__search__close iconsHoverNav" onClick={() => handleCloseSearch()} />
                                <input
                                    type="text"
                                    placeholder="Digite para pesquisar"
                                    value={searchQuery}
                                    onChange={e => setSearchQuery(e.target.value)}
                                />
                            </div>
                        </motion.div>}
                    </AnimatePresence>
                </div>
            </motion.nav>
            <AnimatePresence>
                {showWhatsApp && <motion.div key={'whatsB'} className="floatContainer" initial={{ transform: 'translateX(200px)' }} animate={{ transform: 'translateX(0px)' }} exit={{ transform: 'translateX(200px)' }} transition={{ type: 'spring', bounce: 0.4 }}>
                    <FontAwesomeIcon icon={faClose} className="floatButtonClose" onClick={() => handleFloatButtonClose()} />
                    <div className="floatButton" onClick={() => redirectURL(true, 'https://api.whatsapp.com/send/?phone=5511956384766&text=Ol%C3%A1%21+Gostaria+de+entrar+em+contato+com+a+Flex+Interativa.&type=phone_number&app_absent=0')}>
                        <FontAwesomeIcon icon={faWhatsapp} className="my-floatButton" />
                    </div>
                </motion.div>}
            </AnimatePresence>
        </>
    )
}

export default NavbarX