import React, { useState } from 'react'
import useOuterClick from "../hooks/useOuterClick";
import {
    faChevronUp,
    faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {
    faFacebook,
    faWhatsapp,
    faInstagram,
    faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import { redirectURL } from '../components/functions'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import addressImg from "../assets/images/endereco01.png";

export const FooterFixed = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const innerRef = useOuterClick(() => {
        setIsVisible((prevVisible) => prevVisible === true ? !prevVisible : "");
    });

    const toggleVisibility = () => {
        setIsVisible((prevVisible) => !prevVisible);
    };

    return (
        <>
            <div ref={innerRef} className={`settings ${isOpen ? "open" : ""}`}>
                <div className={`sliding-div ${isVisible ? 'visible' : ''}`}>
                    <div className="row mx-0 w-100">
                        <div className="col-md-12 col-lg-6 mb-3 mb-lg-0">
                            <div className="d-flex borderBlock">
                                <img className="borderFooterImg d-sm-block d-none" src={addressImg} alt="" />
                                <div className="p-2">
                                    <h5>Flex interativa - Matriz</h5>
                                    <p className="mb-0">Rua do Fico, 30 - Ipiranga<br></br>São Paulo, Brasil - 04201-000</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="d-flex borderBlock">
                                <img className="borderFooterImg d-sm-block d-none" src={addressImg} alt="" />
                                <div className="p-2">
                                    <h5>R1 Spaces - World Trade Center</h5>
                                    <p className="mb-0">Av. Nações Unidas, 12.551, Brooklin Novo<br></br>São Paulo, Brasil - 04578‑000</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="videoFooter row mx-0">
                    <div className="col-md-8 col-sm-12  row px-0 mx-0">
                        <div className="col-6 px-0">
                            <p className="mb-0 setAddress" onClick={toggleVisibility} >
                                <FontAwesomeIcon icon={isVisible ? faChevronDown : faChevronUp} className="me-2 iconHover" />
                                Endereço
                            </p>
                        </div>
                        <div className="col-6 flex-center px-0">
                            <FontAwesomeIcon icon={faFacebook} className="me-3 iconsHover" size={"xl"} onClick={() => redirectURL(true, 'https://www.facebook.com/flexinterativaoficial')} />
                            <FontAwesomeIcon icon={faInstagram} className="me-3 iconsHover" size={"xl"} onClick={() => redirectURL(true, 'https://www.instagram.com/flexinterativa/')} />
                            <FontAwesomeIcon icon={faLinkedin} className="me-3 iconsHover" size={"xl"} onClick={() => redirectURL(true, 'https://www.linkedin.com/company/flex-interativa/?originalSubdomain=br')} />
                            <FontAwesomeIcon icon={faWhatsapp} className="iconsHover" size={"xl"} onClick={() => redirectURL(true, 'https://api.whatsapp.com/send/?phone=5511956384766&text=Ol%C3%A1%21+Gostaria+de+entrar+em+contato+com+a+Flex+Interativa.&type=phone_number&app_absent=0')} />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 px-0"><span className="copyright">© 2023 Flex Interativa  - All Rights Reserved.</span></div>
                </footer>
            </div>
        </>
    )
}