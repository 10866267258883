import { useRoutes } from "react-router-dom";
import Intro from '../pages/Intro'
import Projects from '../pages/Projects'
import About from './../pages/About'
import Technologies from './../pages/Technologies'
import PrivacyPolitics from './../pages/PrivacyPolitics'

export const GetRoutes = () => {
    const routeList = useRoutes([
        {
            path: "*",
            element: <Intro />
        },
        {
            path: "/projetos",
            element: (
                <Projects />
            )
        },
        {
            path: "/projetos/:id",
            element: (
                <Projects />
            )
        },
        {
            path: "/tecnologias",
            element: (
                <Technologies />
            )
        },
        {
            path: "/sobre",
            element: (
                <About />
            )
        },
        {
            path: "/politica-privacidade",
            element: (
                <PrivacyPolitics />
            )
        },
    ]);
    return routeList
}
