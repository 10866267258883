
import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import { HelmetProvider } from "react-helmet-async"
import ScrollToTop from "./components/ScrollToTop";
import { AnimatePresence } from "framer-motion"
import { useIntro } from './context/introContext'
import { SearchProvider } from './context/searchContext'
import { useLanguage } from './context/languageContext'
import NavbarX from './components/Navbar'
import { GetRoutes } from './components/Routes'

function App() {
  const { skipIntro, setSkip } = useIntro();
  const location = useLocation()
  const { language, setLanguage } = useLanguage();
  ReactSession.setStoreType("localStorage");

  useEffect(() => {
    let newLanguage = ReactSession.get('flex-language')
    if (language !== newLanguage) {
      setLanguage(language)
    }
    if (location.pathname !== '/' && !skipIntro) {
      setSkip()
    }
  }, [])

  return (
    <HelmetProvider>
      <div className="scrollbar-container">
        <SearchProvider>
          <ScrollToTop />
          {location.pathname !== '/' && <NavbarX></NavbarX>}
          <AnimatePresence mode="wait" initial={false}>
            {React.cloneElement(GetRoutes(), { key: location.pathname })}
          </AnimatePresence>
        </SearchProvider>
      </div>
    </HelmetProvider>
  );
}

export default App;
